import React from 'react';
import PropTypes from 'prop-types';
import Text from '../ui/Text';
import { PlayerContainer, Player } from './styles';

const VideoPlayer = ({ url, description }) => {
  return (
    <>
      {description ? (
        <Text fontSize="lg" mb="48px" align="justify">
          {description}
        </Text>
      ) : null}
      <PlayerContainer>
        <Player url={url} />
      </PlayerContainer>
    </>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
};

VideoPlayer.defaultProps = {
  description: null,
};

export default VideoPlayer;
