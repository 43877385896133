import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import CoursePage from '../../containers/CoursePage';

const CourseTemplate = ({ data, location, pageContext }) => {
  const values = data.contentfulCoursesContent;

  return (
    <CoursePage
      pageContext={pageContext}
      location={location}
      description={values.description}
      title={values.title}
      data={values}
    />
  );
};

export const courseQuery = graphql`
  query($slug: String!) {
    contentfulCoursesContent(slug: { eq: $slug }) {
      title
      description
      contentTitle
      videoLink
      videoDescription
      segment
      segmentDescription
      slug
      content {
        childContentfulRichText {
          html
        }
      }
      logo {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

CourseTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    contentfulCoursesContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      logoTitle: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      videoLink: PropTypes.string,
      videoDescription: PropTypes.string,
      segment: PropTypes.string,
      segmentDescription: PropTypes.string,
      logo: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

export default CourseTemplate;
