import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../../components/seo';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import PageHeader from '../../components/pageheader';
import CourseInfo from '../../components/CourseInfo';
import VideoPlayer from '../../components/VideoPlayer';
import CourseLinkButton from '../../components/CourseLinkButton';
import { Content } from './styles';
import Heading from '../../components/ui/Heading';

const CoursePage = ({ pageContext, location, title, description, data }) => {
  const { logo, logoTitle, content, contentTitle, videoLink, videoDescription, segment, segmentDescription } = data;

  return (
    <>
      <SEO title={title} description={description} image={logo.fluid.src} />
      <Header />
      <PageHeader pageContext={pageContext} location={location} title={title} />
      <Content>
        <Heading as="h3" textalign="center" color="primary">
          {contentTitle}
        </Heading>
        <CourseInfo image={logo.fluid} imageTitle={logoTitle} content={content.childContentfulRichText.html} />
        {videoLink ? <VideoPlayer url={videoLink} description={videoDescription} /> : null}
        {segment ? <CourseLinkButton path={segment} description={segmentDescription} /> : null}
      </Content>
      <Footer />
    </>
  );
};

CoursePage.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    logoTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    videoLink: PropTypes.string,
    videoDescription: PropTypes.string,
    segment: PropTypes.string,
    segmentDescription: PropTypes.string,
    logo: PropTypes.object.isRequired,
    contentTitle: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string,
};

CoursePage.defaultProps = {
  description: undefined,
};

export default CoursePage;
