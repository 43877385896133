import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Img from 'gatsby-image';
import { Container, Description, ImageContainer, ContentContainer } from './styles';

const CourseInfo = ({ image, imageTitle, content }) => {
  return (
    <Container>
      <ContentContainer>
        <ImageContainer>
          <Img fluid={image} alt={imageTitle} title={imageTitle} />
        </ImageContainer>
        <Description>{parse(content)}</Description>
      </ContentContainer>
    </Container>
  );
};

CourseInfo.propTypes = {
  image: PropTypes.object.isRequired,
  imageTitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default CourseInfo;
