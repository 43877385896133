import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';

export const PlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin-bottom: ${({ theme }) => theme.spacings.lg};
`;

export const Player = styled(ReactPlayer).attrs({ width: '100%', height: '100%' })`
  position: absolute;
  top: 0;
  left: 0;
`;
